<template>
  <component :is="step"
    :form="schema"
    :errors="err"
    :prevRouteName="prevRouteName"
    :nextRouteName="nextRouteName"
    :openGuidance="openGuidance"
    :valid="valid"
    :sending="sending"
    :errorType="errorType"
    :submitting="submitting" />
</template>

<script>
import FormStep1 from './FormStep1.vue'
import FormStep2 from './FormStep2.vue'
import FormStep3 from './FormStep3.vue'

export default {
  props: ['form', 'errors', 'prevRouteName', 'nextRouteName', 'valid', 'openGuidance', 'sending', 'errorType', 'submitting'],
  components: {
    FormStep1,
    FormStep2,
    FormStep3
  },
  computed: {
    step () {
      return `form-${this.$route.name}`
    },
    schema () {
      return this.form[this.$route.name]
    },
    err () {
      return this.errors[this.$route.name]
    }
  }
}
</script>
