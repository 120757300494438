<template>
  <ToyamaFormContainer :token="token" />
</template>

<script>
import ToyamaFormContainer from './components/ToyamaFormContainer.vue'

export default {
  name: 'App',
  props: ['token'],
  components: {
    ToyamaFormContainer
  }
}
</script>
