import '../javascripts/stimulusapps.js'
import '../javascripts/news-toggle.js'
import { alertWhenBrowserBack } from '../javascripts/lib/alert_when_browser_back.ts'
import { initializeVueApp } from '../javascripts/lib/initialize_vue_app.ts'
import App from '../javascripts/toyama/App.vue'
import component from '../javascripts/toyama/components/ToyamaFormStepsContainer.vue'

const dom = document.querySelector('#toyama-app')
if (dom !== null) {
  initializeVueApp({ App, dom, component })
}

window.addEventListener('beforeunload', (event) => {
  alertWhenBrowserBack({ appSelector: '#toyama-app', event })
})
